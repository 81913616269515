import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { AuthData } from '@/api/services/userService/types';
import stores from '@/store/core/RootStore';
import router from '@/router';
import services from '@/api/services';

type ExtendedAxiosRequestConfig =
  | (AxiosRequestConfig & {
      isRetry: boolean;
    })
  | undefined;

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const stoWebControlApi = axios.create({
  baseURL: process.env.VUE_APP_STO_WEB_CONTROL_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getLocale = () => localStorage.getItem('lang') || 'en';
const stoCtaNotificationApi = axios.create({
  baseURL: 'https://demo-sto-cta-notification-api.dev-24x7.com/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    locale: getLocale(),
  },
});

const nsiApi = axios.create({
  baseURL: 'https://demo-sto-cta-trs-nsi-api.dev-24x7.com',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    locale: getLocale(),
  },
});

const REQUEST_INTERCEPTORS = [
  (config: InternalAxiosRequestConfig) => {
    if (config.headers) {
      config.headers['Access-Token'] = getBearer(stores.user.token);
    }
    return config;
  },
  (error: any) => error,
];

const RESPONSE_INTERCEPTORS = [
  (response: AxiosResponse) => response,
  async (error: any) => {
    console.warn(error);
    const config = error.config as ExtendedAxiosRequestConfig;
    if (error?.response?.status === 401 && config && !config.isRetry) {
      config.isRetry = true;
      try {
        isRefreshing = isRefreshing ? isRefreshing : refresh();
        const data = await isRefreshing;
        isRefreshing = null;
        stores.user.setKey(data);
        return baseApi.request(config);
      } catch (error) {
        console.error(error);
        stores.user.removeKey();
        await router.push('/auth/login');
      }
    }
    return Promise.reject(error);
  },
];

export const getBearer = (token?: string) => `${token || ''}`;

const refresh = async () => {
  const refreshToken: string = stores.user.refreshToken || '';
  const response = await services.user.refresh({ refreshToken });
  return response.data;
};

let isRefreshing: Promise<AuthData> | null = null;

baseApi.interceptors.request.use(...REQUEST_INTERCEPTORS);
baseApi.interceptors.response.use(...RESPONSE_INTERCEPTORS);

stoWebControlApi.interceptors.request.use(...REQUEST_INTERCEPTORS);
stoWebControlApi.interceptors.response.use(...RESPONSE_INTERCEPTORS);

stoCtaNotificationApi.interceptors.request.use(
  // @ts-ignore
  (config) => {
    config.headers.locale = getLocale();
    return config;
  },
);

nsiApi.interceptors.request.use(
  // @ts-ignore
  (config) => {
    config.headers.locale = getLocale();
    return config;
  },
);

export { baseApi, stoWebControlApi, nsiApi, stoCtaNotificationApi };
